<template>
  <button
    class="h-8 flex justify-center items-center dark:bg-gray-850 dark:text-gray-300 bg-white border shadow-sm rounded px-3 text-gray-500 hover:text-primary-500 dark:hover:text-gray-300 dark:hover:bg-gray-700"
    @click="$nuxt.callHook('modal-open', 'modal-product-view')"
  >
    <Icon class="w-5 h-5 me-1" :name="view.icon" />
    <span class="text-sm leading-none">{{ $t(view.label) }}</span>
  </button>
</template>

<script>
import { mapState } from "pinia";
import { useProductPrefStore } from "@/store/pref.js";

export default {
  computed: {
    ...mapState(useProductPrefStore, { view: "productView" }),
  },
};
</script>
