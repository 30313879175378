<template>
  <div class="container dark:bg-gray-900">
    <div
      class="p-4 border-b dark:border-gray-700 sm:flex sm:items-center dark:bg-gray-900 sm:px-0 sm:pt-8 md:pt-12 sm:border-0"
    >
      <!-- TITLE -->
      <div
        v-if="loading"
        class="block w-64 h-6 bg-shimmer animate-pulse rounded-md lg:h-8 dark:bg-gray-850"
      ></div>
      <p
        class="font-display font-bold text-xl leading-none lg:text-4xl text-gray-900 dark:text-gray-300"
      >
        {{ data.title }}
      </p>
      <!-- ACTIONS -->
      <div
        class="flex items-center flex-shrink-0 space-x-1 rtl:space-x-reverse mt-2 sm:mt-0 sm:ms-auto"
      >
        <StoreViewButton />
        <StoreSortButton />
      </div>
    </div>
  </div>
</template>

<script>
import propsViaApi from "@/mixins/props-via-api";
export default {
  mixins: [propsViaApi],
  props: {
    title: String,
  },
};
</script>
