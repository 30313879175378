<template>
  <Modal id="modal-sort-products" v-slot="{ close }">
    <ModalShell size="sm" :title="$t('sort_by')" :close="close">
      <DropdownList
        :options="$options.sortOptions"
        :value="sortBy"
        @update="setData"
      >
        <template #icon="{ active, option }">
          <Check :checked="active(option)" checked-theme="primary" />
        </template>
      </DropdownList>
    </ModalShell>
  </Modal>
</template>

<script>
import options from "@/assets/sort-options";
import { useProductPrefStore } from "@/store/pref.js";
import { mapState, mapActions } from "pinia";

export default {
  sortOptions: options,
  emits: ["close"],

  computed: {
    ...mapState(useProductPrefStore, ["sortBy"]),
  },
  methods: {
    ...mapActions(useProductPrefStore, ["set"]),
    setData(value) {
      this.set({
        key: "sortBy",
        value,
      });
      this.$emit("close");
      this.$nuxt.callHook("modal-close", "modal-sort-products");
    },
  },
};
</script>
