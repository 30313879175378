<template>
  <div class="container dark:bg-gray-900 dark:text-gray-500">
    <!-- List -->

    <ClientOnly>
      <VList
        :key="category"
        ref="list"
        endpoint="products"
        :params="{ variables: { hash } }"
        :filters="{ categorySlug: category, name: $route.query.search }"
        :sort-by="sortBy"
        :per-page="30"
      >
        <template #empty>
          <EmptyList title="No Products Found!" icon="PhPackageDuotone" />
        </template>

        <template #loading-more>
          <div class="product-grid" :class="`product-grid--${view.value}`">
            <ShimmerProduct v-for="n in 30" :key="`shimmer-${n}`" />
          </div>
        </template>

        <template #loading-page>
          <div class="product-grid" :class="`product-grid--${view.value}`">
            <ShimmerProduct v-for="n in 30" :key="`shimmer-${n}`" />
          </div>
        </template>

        <template #loading>
          <div class="product-grid" :class="`product-grid--${view.value}`">
            <ShimmerProduct v-for="n in 30" :key="`shimmer-${n}`" />
          </div>
        </template>

        <template #default="response">
          <div class="product-grid" :class="`product-grid--${view.value}`">
            <div
              v-for="product in response.items"
              :key="product.id"
              class="dark:bg-gray-900 dark:shadow dark:rounded"
            >
              <StoreProduct
                :id="parseInt(product.id)"
                :name="product.name"
                :media="product.media"
                :badges="product.tags"
                :regular-price="product.regularPrice"
                :price="product.price"
                :type="product.type"
                :measurement="product.measurementWithUnit"
                :stock="product.stock"
                :is-out-of-stock="product.isOutOfStock"
                :allow-qty="product.allowQty"
                :slug="product.slug"
                :manage-inventory="product.manageInventory"
              />
            </div>
          </div>

          <div class="flex justify-center p-8">
            <VListLoadMore>
              <template #default="{ loading, loadMore }">
                <VyButton
                  :loading="loading"
                  color="primary"
                  theme="solid"
                  shape="rounded"
                  size="lg"
                  :label="$t('load_more')"
                  @click="loadMore()"
                />
              </template>
              <template #end> — {{ $t("list-thats-all") }} — </template>
            </VListLoadMore>
          </div>
        </template>
      </VList>
    </ClientOnly>

    <ModalSortProducts @close="refresh()" />
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useVepaarStore } from "@/store/store.js";
import { useProductPrefStore } from "@/store/pref.js";

export default {
  props: {
    category: {
      type: String,
      default: null,
    },
  },

  computed: {
    ...mapState(useVepaarStore, ["hash"]),
    ...mapState(useProductPrefStore, ["sortBy"]),
    ...mapState(useProductPrefStore, { view: "productView" }),
  },

  methods: {
    refresh() {
      this.$nextTick(() => {
        this.$refs.list.refresh();
      });
    },
  },
};
</script>

<style>
.product-grid {
  @apply divide-y sm:divide-y-0 sm:grid sm:gap-3 md:grid-cols-2 lg:grid-cols-3;
}

.product-grid--grid {
  @apply divide-none grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4;
  > div {
    @apply -ms-px -mt-px;
  }
}
</style>
