<template>
  <article
    class="h-full p-4 sm:border sm:rounded-md hover:ring-4 hover:ring-primary-100 hover:border-primary-100 dark:bg-gray-900 dark:ring-gray-700 dark:hover:border-gray-700 dark:hover:ring-1 dark:border-gray-800 dark:border-2"
  >
    <div class="flex">
      <!-- Left -->
      <VLink class="flex-shrink-0 w-12" :to="link">
        <StoreProductMedia :value="media" :alt="name" ratio />
      </VLink>

      <!-- Center -->
      <div class="flex-grow ms-3">
        <!-- Name -->
        <VLink
          :to="link"
          class="block text-sm leading-snug break-word dark:text-white"
        >
          {{ name }}
        </VLink>

        <!-- Price -->
        <div class="flex items-center mt-1 dark:text-white">
          <StoreProductPrice
            :type="type"
            :regular="regularPrice"
            :value="price"
          />
          <p
            v-if="measurement"
            class="flex-shrink-0 text-xs leading-snug text-gray-500 ms-2"
          >
            /{{ measurement }}
          </p>
        </div>
      </div>

      <!-- right -->
      <div class="ms-3">
        <!-- Add To Cart: Simple Product -->
        <StoreCartAddToCart
          v-if="type === 'simple' || type === 'digital'"
          :id="id"
          :max="stock"
          :is-out-of-stock="isOutOfStock"
          :allow-qty="allowQty"
          :type="type"
          :manage-inventory="manageInventory"
        />

        <!-- Add To Cart: Variable Product -->
        <VyButton
          v-else-if="type === 'variable'"
          color="primary"
          theme="muted"
          shape="rounded"
          size="md"
          :label="$t('add_to_cart')"
          @click="$nuxt.callHook('modal-open', 'modal-product-variant', { id })"
        />
      </div>
    </div>
    <StoreProductBadges
      class="mt-2"
      :badges="badges"
      :product-id="parseInt(id)"
    />
  </article>
</template>

<script>
export default {
  props: {
    id: { type: Number, default: null },
    name: { type: String, default: null },
    media: { type: Array, default: null },
    badges: { type: Array, default: null },
    price: { type: Number, default: null },
    regularPrice: { type: Number, default: null },
    type: { type: String, default: null },
    measurement: { type: String, default: null },
    stock: { type: Number, default: null },
    isOutOfStock: { type: Boolean, default: null },
    allowQty: { type: Boolean, default: null },
    slug: { type: String, default: null },
    link: { type: Object, default: null },
    manageInventory: { type: Boolean, default: null },
  },
};
</script>
