<template>
  <StoreProductCompactView
    v-if="view.value == 'compact'"
    v-bind="$attrs"
    :link="productLink"
  />
  <StoreProductGridView
    v-else-if="view.value == 'grid'"
    v-bind="$attrs"
    :link="productLink"
  />
  <StoreProductListView v-else v-bind="$attrs" :link="productLink" />
</template>

<script>
import { useProductPrefStore } from "@/store/pref.js";

export default {
  inheritAttrs: false,
  setup() {
    const productPrefStore = useProductPrefStore();
    const view = computed(() => productPrefStore.productView);
    return { view };
  },
  computed: {
    productLink() {
      return { name: "biz-products-slug", params: { slug: this.$attrs.slug } };
    },
  },
};
</script>
