<template>
  <article
    class="h-full p-4 dark:bg-gray-900 sm:border sm:rounded-md hover:ring-4 hover:ring-primary-100 hover:border-primary-100 dark:ring-gray-700 dark:hover:border-gray-700 dark:hover:ring-1 dark:border-gray-800 dark:border-2"
  >
    <div class="flex">
      <VLink class="flex-shrink-0 w-24" :to="link">
        <StoreProductMedia :value="media" :alt="name" ratio />
      </VLink>
      <div class="ms-4">
        <VLink
          :to="link"
          class="block text-sm leading-snug break-word dark:text-white"
        >
          {{ name }}
        </VLink>
        <StoreProductBadges
          class="mt-2"
          :badges="badges"
          :product-id="parseInt(id)"
        />
      </div>
    </div>
    <div class="flex items-center justify-between mt-2 dark:text-white">
      <!-- Left -->
      <div>
        <StoreProductPrice
          :type="type"
          :regular="regularPrice"
          :value="price"
        />
        <p
          v-if="measurement"
          class="flex-shrink-0 text-xs leading-snug text-gray-500"
        >
          {{ measurement }}
        </p>
      </div>

      <!-- Right -->
      <!-- Add To Cart: Simple Product -->
      <StoreCartAddToCart
        v-if="type === 'simple' || type === 'digital'"
        :id="id"
        :max="stock"
        :is-out-of-stock="isOutOfStock"
        :allow-qty="allowQty"
        :manage-inventory="manageInventory"
        :type="type"
      />

      <!-- Add To Cart: Variable Product -->
      <VyButton
        v-else-if="type === 'variable'"
        size="md"
        color="primary"
        shape="rounded"
        theme="muted"
        :label="$t('add_to_cart')"
        @click="$nuxt.callHook('modal-open', 'modal-product-variant', { id })"
      />
    </div>
  </article>
</template>

<script>
export default {
  props: {
    id: { type: Number, default: null },
    name: { type: String, default: null },
    media: { type: Array, default: null },
    badges: { type: Array, default: null },
    price: { type: Number, default: null },
    regularPrice: { type: Number, default: null },
    type: { type: String, default: null },
    measurement: { type: String, default: null },
    stock: { type: Number, default: null },
    isOutOfStock: { type: Boolean, default: null },
    allowQty: { type: Boolean, default: null },
    slug: { type: String, default: null },
    link: { type: Object, default: null },
    manageInventory: { type: Boolean, default: null },
  },
};
</script>
